import { render, staticRenderFns } from "./App.vue?vue&type=template&id=3f354419&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/_vuetify-loader@1.7.3@vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VApp,VMain})


/* vuetify-loader */
import installDirectives from "!../node_modules/_vuetify-loader@1.7.3@vuetify-loader/lib/runtime/installDirectives.js"
import Scroll from 'vuetify/lib/directives/scroll'
installDirectives(component, {Scroll})
