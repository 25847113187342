<template>
  <div class="header">
    <v-app-bar class="app-bar" app color="#212121" dark flat height="92px">
      <v-container class="py-0 fill-height justify-space-between flex-nowrap">
        <div class="d-flex align-center" @click="logoClickHandler()">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2 logo"
            contain
            src="@/assets/images/logo.png"
            transition="scale-transition"
          />
        </div>

        <v-spacer></v-spacer>

        <div class="close-area d-md-none" @click="menuShow = !menuShow">
          <div class="strip1" :class="{ active: menuShow }"></div>
          <div class="strip2" :class="{ active: menuShow }"></div>
          <div class="strip3" :class="{ active: menuShow }"></div>
        </div>

        <ul class="menu d-none d-md-flex justify-end">
          <li
            class="menu-tab"
            :class="{ active: idx === index }"
            v-for="(item, index) in menu"
            :key="index"
            @click="menuClickHandler(index, item.tag)"
          >
            {{ item.content }}
            <div class="tab-active" v-show="idx === index">
              <div class="point"></div>
              <div class="point"></div>
            </div>
          </li>
        </ul>
      </v-container>
    </v-app-bar>

    <transition name="swipe">
      <div class="m-menu d-md-none" v-if="menuShow">
        <ul>
          <li
            v-for="(item, index) in menu"
            :key="index"
            @click="menuClickHandler(index, item.tag)"
          >
            {{ item.content }}
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'headerBar',
  data: () => ({
    drawer: false,
    menuShow: false,
    menu: [
      { content: '首页', tag: 'Home' },
      { content: '产品介绍', tag: 'Productions' },
      { content: '方案优势', tag: 'Advantages' },
      { content: '应用场景', tag: 'Scenes' },
      { content: '合作伙伴', tag: 'Partners' },
      { content: '关于我们', tag: 'About' },
    ],
  }),
  props: ['idx'],
  methods: {
    logoClickHandler() {
      this.$router.replace('/')
    },
    goContactPage(channel) {
      console.log('contact:', channel)
      switch (channel) {
        case 'discord':
          window.open('https://discord.gg/zKVKwxy')
          this.$nenlyTracker({
            action: 'click_discord',
          })
          break
        case 'twitter':
          window.open('https://twitter.com/CloudCa34374434')
          this.$nenlyTracker({
            action: 'click_twitter',
          })
          break
        case 'facebook':
          window.open('https://www.facebook.com/CloudCatApp/')
          this.$nenlyTracker({
            action: 'click_facebook',
          })
          break
      }
    },
    overlayClickHandler() {
      this.menuShow = false
    },
    menuClickHandler(index, tag) {
      // this.$router.push({ name: tag })
      this.$vuetify.goTo('#' + tag)
      setTimeout(() => {
        this.$emit('update:idx', index)
      }, 100)
      this.menuShow = false
    },
    link(item) {
      if (item === 'TRY FOR FREE') {
        this.tryBtnClickHandler()
      } else {
        let params = this.$route.fullPath.substr(1)
        this.$nenlyTracker({
          action: 'click_link',
          object: {
            link_text: item,
          },
        })
        location.href = 'https://cloudcat.ai/' + params
      }
    },
    tryBtnClickHandler() {
      let params = this.$route.fullPath.substr(1)
      let UserClient = navigator.userAgent.toLowerCase()
      let IsIPad = UserClient.match(/ipad/i) == 'ipad'
      let IsIphoneOs = UserClient.match(/iphone os/i) == 'iphone os'
      let IsMidp = UserClient.match(/midp/i) == 'midp'
      let IsUc7 = UserClient.match(/rv:1.2.3.4/i) == 'rv:1.2.3.4'
      let IsUc = UserClient.match(/ucweb/i) == 'ucweb'
      let IsAndroid = UserClient.match(/android/i) == 'android'
      let IsCE = UserClient.match(/windows ce/i) == 'windows ce'
      let IsWM = UserClient.match(/windows mobile/i) == 'windows mobile'
      if (
        IsIPad ||
        IsIphoneOs ||
        IsMidp ||
        IsUc7 ||
        IsUc ||
        IsAndroid ||
        IsCE ||
        IsWM
      ) {
        this.$nenlyTracker({
          action: 'click_trial_button',
        })
        window.location.href = 'https://m.cloudcat.ai' + params
      } else {
        this.$nenlyTracker({
          action: 'click_trial_button',
        })
        window.location.href = 'https://pc.cloudcat.ai' + params
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@keyframes fade-rotate {
  0% {
    opacity: 0;
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(0);
    opacity: 1;
  }
}

.close-area {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .strip1,
  .strip2,
  .strip3 {
    width: 24px;
    height: 3px;
    background: #ffffff;
    margin: 2px;
    transition: 0.4s;
    border-radius: 2px;
  }
  .strip1 {
    &.active {
      transform: rotate(-45deg) translate(-5px, 5px);
    }
  }
  .strip2 {
    &.active {
      opacity: 0;
    }
  }
  .strip3 {
    &.active {
      transform: rotate(45deg) translate(-5px, -5px);
    }
  }
}
.header {
  box-shadow: 4px 4px 4px #868686;
}
.logo {
  width: 124px;
  margin-left: 20px;
}
.logo-text {
  font-size: 32px;
  font-weight: bold;
}
.menu {
  width: auto;
  font-weight: 100;
  .menu-tab {
    position: relative;
    min-width: 80px;
    min-height: 30px;
    padding: 0 8px;
    margin: 0 8px;
    text-transform: none;
    border-radius: 4px;
    list-style: none;
    cursor: pointer;
    text-align: center;
    transition: all linear;
    font-size: 16px;
    &.active {
      font-weight: bold;
    }
    &:hover {
      font-weight: bold;
    }
    .tab-active {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      .point {
        width: 5px;
        height: 5px;
        border-radius: 3px;
        margin: 0 2px;
        background-color: white;
      }
    }
  }
}
.m-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  padding-top: 92px;
  background: linear-gradient(180deg, #000000 21.61%, #2d2d2d 100%);
  border-radius: 0 0 20px 20px;
  &__overlay {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
  }
  ul {
    list-style: none;
    padding: 0 32px;
    text-align: center;
    li {
      border-bottom: 1px solid #3d3d3d;
      padding: 20px;
      font-size: 14px;
      font-weight: bold;
    }
  }
  .link {
    padding: 20px 0 40px;
    text-align: center;
    width: 100%;
    img {
      width: 22px;
      margin-left: 20px;
      cursor: pointer;
    }
  }
}
.swipe-enter-active {
  animation: swipe-in 0.5s;
}
.swipe-leave-active {
  animation: swipe-out 0.5s;
}
@keyframes swipe-in {
  0% {
    transform: translateY(-100%);
  }
  100% {
  }
}
@keyframes swipe-out {
  0% {
  }
  100% {
    transform: translateY(-100%);
  }
}
@media screen and (max-width: 600px) {
  .logo {
    width: 94px;
    margin-left: 16px;
  }
  .logo-text {
    font-size: 24px;
    font-weight: bold;
  }
}
</style>
