<template>
  <v-container class="wrapper scenes" id="Scenes">
    <h1 class="h-title margin">应用场景</h1>
    <v-row class="rows">
      <v-col cols="12" md="4" class="cols">
        <div class="box scene">
          <div class="img-placeholder"></div>
          <h2>云游戏</h2>
          <h3>无需用户配置高性能手机，<br />多终端随时随地玩各类大作。</h3>
          <img
            src="@/assets/images/Group 97828.png"
            class="img-scences img-1 d-none d-md-block"
            alt=""
          />
          <img
            src="@/assets/images/Group 97964.png"
            class="d-md-none img-scences m-img-1"
            alt=""
          />
        </div>
      </v-col>
      <v-col cols="12" md="4" class="cols">
        <div class="box scene">
          <div class="img-placeholder"></div>
          <h2>试玩广告</h2>
          <h3>无需下载，即点即玩，<br />提升游戏转化、激活等各项指标。</h3>
          <img
            src="@/assets/images/Group 97838.png"
            class="img-scences img-2 d-none d-md-block"
            alt=""
          />
          <img
            src="@/assets/images/Group 97837.png"
            class="d-md-none img-scences m-img-2"
            alt=""
          />
        </div>
      </v-col>
      <v-col cols="12" md="4" class="cols">
        <div class="box scene">
          <div class="img-placeholder"></div>
          <h2>云原生游戏</h2>
          <h3>游戏制作不受终端硬件限制，<br />上万人同场景即时互动。</h3>
          <img
            src="@/assets/images/Group 97937.png"
            class="img-scences img-3 d-none d-md-block"
            alt=""
          />
          <img
            src="@/assets/images/Group 97965.png"
            class="d-md-none img-scences m-img-3"
            alt=""
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Scenes',
}
</script>

<style lang="scss" scoped>
// scenes
.scenes {
  .rows,
  .cols {
    overflow: visible;
  }
  .rows {
    padding: 0 30px;
  }
  .box {
    position: relative;
    overflow: visible;
    padding: 24px;
    &.scene {
      padding: 18px;
    }
    .img-placeholder {
      width: 100%;
      height: 360px;
    }
    h2 {
      font-size: 28px;
      margin-bottom: 16px;
    }
    h3 {
      font-size: 16px;
    }
    .img-scences {
      position: absolute;
      top: 0;
      z-index: 9;
    }
    .img-1 {
      width: 370px;
      left: -40px;
    }
    .img-2 {
      width: 350px;
      right: -40px;
    }
    .img-3 {
      width: 353px;
      top: 20px;
      right: -30px;
    }
  }
  @media screen and(max-width: 600px) {
    .rows {
      padding: 18px;
    }
    .box {
      padding: 18px;
      .img-placeholder {
        height: 160px;
      }
      h2 {
        font-size: 20px;
        margin-bottom: 8px;
        max-width: 60%;
      }
      h3 {
        font-size: 14px;
        width: 70%;
        max-width: 206px;
      }
      .m-img-1 {
        width: 100%;
        left: -30px;
        top: -50px;
        max-width: 347px;
      }
      .m-img-2 {
        width: 100%;
        top: 38px;
        left: 0;
        max-width: 331px;
      }
      .m-img-3 {
        width: 100%;
        top: -20px;
        max-width: 331px;
      }
    }
  }
}
</style>
