<template>
  <div class="page" id="page">
    <div class="dialog-demo" v-if="demoPage">
      <div class="mask" @click="closeDemoPage"></div>
      <iframe
        class="demo-page vertical-game"
        src="https://demo5.nenly.cn/"
        frameborder="0"
      ></iframe>
    </div>
    <!-- Home -->
    <v-container class="home" id="Home">
      <v-img
        src="@/assets/images/banner-nenly.png?vuetify-preload"
        class="img-banner-nenly d-none d-md-block animate__animated animate__backInDown"
        alt=""
      />
      <img
        src="@/assets/images/m-bg-banner.png"
        class="d-md-none animate__animated animate__fadeIn"
        style="width: 100%; margin: -12px 0 -24px"
        alt=""
      />
      <h1 class="h-title-index">运用云游戏技术打造下一代交互式体验</h1>
      <h2 class="h-subtitle-index">基于混合多云的全球化手机云游戏技术</h2>
      <v-img
        src="@/assets/images/pc-bg-home.png?vuetify-preload"
        class="pc-img-bg d-none d-md-block animate__animated animate__fadeIn"
        alt=""
      />
      <div class="m-bg-container">
        <v-img
          src="@/assets/images/m-bg-home.png?vuetify-preload"
          class="m-img-bg d-md-none animate__animated animate__fadeIn"
        />
      </div>
    </v-container>

    <!-- productions -->
    <Productions />

    <!-- advantages -->
    <Advantages />

    <!-- scenes -->
    <Scenes />

    <!-- demo -->
<!--    <v-container class="wrapper demo" id="Demo">-->
<!--      <h1 class="h-title margin">试玩 Demo</h1>-->
<!--      <img-->
<!--        src="@/assets/images/Group 97994.png"-->
<!--        class="img-bg-demo d-none d-md-block"-->
<!--        alt=""-->
<!--        @click="tryDemo"-->
<!--      />-->
<!--      <img-->
<!--        src="@/assets/images/Group 97738.png"-->
<!--        class="d-md-none m-demo-phone"-->
<!--        alt=""-->
<!--        @click="tryDemo"-->
<!--      />-->
      <!--      <div class="btn-demo">-->
      <!--        立即体验云游戏-->
      <!--        <img src="@/assets/images/pointer.png" alt="" />-->
      <!--      </div>-->
<!--    </v-container>-->

    <!-- partners -->
    <Partners />

    <!-- about us -->
    <About />
  </div>
</template>

<script>
import Productions from './Productions'
import Advantages from './Advantages'
import Scenes from './Scenes'
import About from './About'
import Partners from './Partners'
export default {
  name: 'Home',
  components: {
    Partners,
    About,
    Scenes,
    Productions,
    Advantages,
  },
  data() {
    return {
      tag: 'Home',
      demoPage: false,
    }
  },
  watch: {
    tag(val) {
      this.$emit('switchTab', val)
    },
  },
  methods: {
    tryDemo() {
      if (document.body.clientWidth < 600) {
        location.href = 'https://demo5.nenly.cn/'
      } else {
        this.demoPage = true
      }
    },
    closeDemoPage() {
      this.demoPage = false
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-demo {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba($color: #000000, $alpha: 0.6);
  }
  .demo-page {
    position: relative;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);
    z-index: 1001;
    width: 360px;
    height: 640px;
    &.vertical-game {
    }
    &.horizontal-game {
      transform: rotate(-90deg);
      transform-origin: center center;
    }
  }
}
// home
.home {
  text-align: center;
  overflow: hidden;
  .h-title-index {
    max-width: 90%;
    margin: 0 auto;
    font-size: 42px;
    font-weight: bold;
    // opacity: 0.5;
    text-shadow: 0 0 4px rgba(255, 255, 255, 0.4);
  }
  .h-subtitle-index {
    display: inline-block;
    background-color: #fff;
    border-radius: 4px;
    font-size: 24px;
    color: #212121;
    padding: 2px 8px;
    opacity: 0.8;
    margin-top: 24px;
  }
  .img-banner-nenly {
    width: 50%;
    max-width: 560px;
    margin: 0 auto;
    display: block;
  }
  .pc-img-bg {
    width: 100%;
    max-width: 870px;
    margin: 0 auto;
  }

  @media screen and (max-width: 600px) {
    .h-title-index {
      font-size: 32px;
    }
    .h-subtitle-index {
      font-size: 18px;
    }
    .m-bg-container {
      margin-top: 32px;
      position: relative;
      padding-bottom: 400px;
      .m-img-bg {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 110%;
      }
    }
  }
}

// demo
.demo {
  text-align: center;
  .img-bg-demo {
    width: 70%;
    max-width: 650px;
    margin: 0 auto;
    cursor: pointer;
  }
  .m-demo-phone {
    width: 80%;
  }
  .btn-demo {
    width: 180px;
    height: 40px;
    line-height: 40px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 55px;
    text-align: center;
    img {
      width: 17px;
      vertical-align: middle;
    }
  }
  .btn {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 30px;
    padding: 4px 32px;
    outline: none;
    font-size: 30px;
    font-weight: bold;
    margin-top: 32px;
    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.6);
    img {
      width: 46px;
      vertical-align: middle;
    }
  }
}
@media screen and(max-width: 600px) {
  .demo {
    background: url('../assets/images/Group 97739.png') no-repeat;
    background-size: 50%;
    background-position: 90% 0;
    .btn {
      font-size: 24px;
      margin-top: 20px;
      img {
        width: 36px;
      }
    }
  }
}
</style>
