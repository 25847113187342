import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import About from '../views/About.vue'
// import Advantages from '../views/Advantages.vue'
// import Partners from '../views/Partners.vue'
// import Productions from '../views/Productions.vue'
// import Scenes from '../views/Scenes.vue'
import Privacy from '../views/Privacy.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      idx: 0,
    },
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: About,
  //   meta: {
  //     idx: 5,
  //   },
  // },
  // {
  //   path: '/advantages',
  //   name: 'Advantages',
  //   component: Advantages,
  //   meta: {
  //     idx: 2,
  //   },
  // },
  // {
  //   path: '/partners',
  //   name: 'Partners',
  //   component: Partners,
  //   meta: {
  //     idx: 4,
  //   },
  // },
  // {
  //   path: '/productions',
  //   name: 'Productions',
  //   component: Productions,
  //   meta: {
  //     idx: 1,
  //   },
  // },
  // {
  //   path: '/scenes',
  //   name: 'Scenes',
  //   component: Scenes,
  //   meta: {
  //     idx: 3,
  //   },
  // },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    meta: {
      idx: 6,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
