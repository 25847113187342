<template>
  <v-container class="wrapper partners" id="Partners">
    <div class="d-none d-md-block">
      <div class="box1 d-flex">
        <div class="h-title">
          <div class="point"></div>
          合作伙伴
        </div>
        <div class="partners">
          <img src="@/assets/images/Group 97801.png" alt="" />
          <img src="@/assets/images/Group 97803.png" alt="" />
          <img src="@/assets/images/Group 20.png" alt="" />
          <img src="@/assets/images/Group 97857.png" alt="" />
          <img src="@/assets/images/Group 97804.png" alt="" />
          <img src="@/assets/images/Group 97802.png" alt="" />
        </div>
      </div>
      <div class="box1 d-flex">
        <div class="h-title">
          <div class="point"></div>
          投资机构
        </div>
        <div class="partners">
          <img src="@/assets/images/image 64 (Traced).png" alt="" />
          <img src="@/assets/images/Group 97724.png" alt="" />
          <img src="@/assets/images/Group 97723.png" alt="" />
        </div>
      </div>
    </div>
    <div class="d-md-none m-wrapper">
      <div class="box">
        <span class="h-title">合作伙伴</span>
        <div class="partners">
          <img src="@/assets/images/Group 97801.png" alt="" />
          <img src="@/assets/images/Group 97803.png" alt="" />
          <img src="@/assets/images/Group 20.png" alt="" />
          <img src="@/assets/images/Group 97857.png" alt="" />
          <img src="@/assets/images/Group 97804.png" alt="" />
          <img src="@/assets/images/Group 97802.png" alt="" />
        </div>
      </div>
      <div class="box" style="margin-top: 32px">
        <span class="h-title">投资机构</span>
        <div class="partners line">
          <img src="@/assets/images/image 64 (Traced).png" alt="" />
          <img src="@/assets/images/Group 97724.png" alt="" />
          <img src="@/assets/images/Group 97723.png" alt="" />
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'Partners',
}
</script>

<style lang="scss" scoped>
// partners
.partners {
  .m-wrapper {
    padding: 0 18px;
  }
  .box {
    .h-title {
      padding: 4px 12px;
      background: #424242;
      opacity: 0.9;
      box-shadow: 0px 7px 57px rgba(0, 0, 0, 0.4);
      margin-left: -36px;
      font-size: 20px;
      font-weight: bold;
    }
    .partners {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 32px;
      img {
        width: 46%;
        margin: 6px 0;
      }
      &.line {
        margin-top: 0;
        img {
          width: 51%;
          margin: 32px auto 0;
        }
      }
    }
  }
  .box1 {
    background: linear-gradient(209.62deg, #212121 13.78%, #2b2b2b 83.18%);
    opacity: 0.9;
    box-shadow: 0px 7px 57px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 30px;
    font-size: 18px;
    border: 4px solid #262626;
    box-sizing: border-box;
    margin: 40px auto;
    .h-title {
      font-size: 32px;
      text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.4);
      min-width: 28%;
      display: flex;
      align-items: center;
      font-weight: bold;
      .point {
        width: 12px;
        height: 12px;
        border-radius: 6px;
        background: #fff;
        filter: blur(1px);
        margin-right: 12px;
      }
    }
    .partners {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      img {
        width: 30%;
        margin: 15px 0;
      }
    }
  }
}
</style>
