<template>
  <v-container class="wrapper about-us" id="About">
    <div class="dialog" v-if="jobDialog">
      <div class="mask" @click="jobDialog = false"></div>
      <div class="dialog-box" @click="jobDialog = false">
        <div class="close-btn">
          <div class="line line-1"></div>
          <div class="line line-2"></div>
        </div>
        <div class="point"></div>
        <div class="box-title">
          {{ job.name }}
          <div class="badge">{{ job.type }}</div>
        </div>
        <div class="box-content">
          <div class="block" v-if="job.responsibility.length > 0">
            <p>岗位职责：</p>
            <ol>
              <li v-for="(item, index) in job.responsibility" :key="index">
                {{ item }}
              </li>
            </ol>
          </div>
          <div class="block" v-if="job.demand.length > 0">
            <p>岗位要求：</p>
            <ol>
              <li v-for="(item, index) in job.demand" :key="index">
                {{ item }}
              </li>
            </ol>
          </div>
          <div class="block" v-if="job.extra.length > 0">
            <p>加分项：</p>
            <ol>
              <li v-for="(item, index) in job.extra" :key="index">
                {{ item }}
              </li>
            </ol>
          </div>
          <div class="block" v-if="job.desc.tip">
            <p>职位描述：</p>
            <div style="margin-bottom: 12px">{{ job.desc.tip }}</div>
            <ol>
              <li v-for="(item, index) in job.desc.list" :key="index">
                {{ item }}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="about">
      <h1 class="h-title d-md-none">念力致力于云原生游戏的探索与研发</h1>
      <div class="box1">
        <img src="@/assets/images/Group 97720.png" class="tip" alt="" />
        <h1 class="h-title d-none d-md-block">
          念力致力于云原生游戏的探索与研发
        </h1>
        <p>
          「技术创造世界」云原生游戏将带来革命性的全新游戏体验，在打通多端设备限制和打破硬件限制的基础上，
          为玩家提供更优质真实的游戏画面和无人数限制的同服同屏交互，实现“全真互联网”。
        </p>
        <p>
          念力科技于2019年正式运营， 核心创始团队来自谷歌云游戏部门，
          立足于中国，独立自主研发云游戏底层技术。至今已获得五源资本、奇绩创坛、
          莉莉丝数千万美元的投资。
        </p>
      </div>
      <div class="box1 member">
        <img src="@/assets/images/Group 97721.png" class="m-tip" alt="" />
        <v-row class="justify-space-between">
          <v-col cols="12" md="6" class="col col-border">
            <P class="m-title"><span class="badge blue">CEO</span>范子瑜</P>
            <p class="m-row">
              <img src="@/assets/images/google.png" alt="" />
              Google云游戏创始成员
            </p>
            <p class="m-row">
              <img src="@/assets/images/image 1.png" alt="" />
              康奈尔大学计算机系
            </p>
          </v-col>
          <v-col cols="12" md="6" class="col">
            <P class="m-title"><span class="badge">CTO</span>郑暾</P>
            <p class="m-row">
              <img src="@/assets/images/remix.png" alt="" />
              Remix OS 技术负责人，后被Google收购
            </p>
            <p class="m-row">
              <img src="@/assets/images/Group 97680.png" alt="" />
              清华大学计算机系
            </p>
            <p class="m-row">
              <img src="@/assets/images/Group 97681.png" alt="" />
              NOI金牌得主; 独立游戏开发者
            </p>
          </v-col>
        </v-row>
      </div>

      <!-- join us -->
      <h1 class="h-title d-none d-md-block" style="margin-top: 64px">
        加入我们
      </h1>
      <div class="box1 join d-none d-md-block">
        <img src="@/assets/images/gamepad.png" alt="" />
        <h1 class="h-title d-md-none title-join">加入我们</h1>
        在念力，我们脚踏实地，效率至上，聚焦做出用户热爱的产品。<br />
        我们相信云游戏技术是未来虚拟世界的操作系统，<br />
        一群优秀的人共同探索云游戏技术的无限可能，<br />
        终能打造属于我们的云原生游戏。<br />
        如果你也是追求高质量的游戏爱好者，请加入我们共筑虚拟世界未来。<br />
      </div>
      <div class="box1 join d-md-none">
        <img src="@/assets/images/gamepad.png" alt="" />
        <h1 class="h-title d-md-none title-join">加入我们</h1>
        <p>在念力，我们脚踏实地，效率至上， 聚焦做出用户热爱的产品。</p>
        <p>
          我们相信云游戏技术是未来虚拟世界的操作系统，一群优秀的人共同探索云游戏技术的无限可能，终能打造属于我们的云原生游戏。
        </p>
        如果你也是追求高质量的游戏爱好者，请加入我们共筑虚拟世界未来。<br />
      </div>
      <h1 class="h-title d-none d-md-block">招聘职位</h1>
      <v-row class="d-none d-md-flex jobs">
        <v-col
          class="box3-wrapper"
          cols="12"
          md="6"
          v-for="(item, index) in jobs"
          :key="index"
          @click="jobClickHandler(item)"
        >
          <div class="box3">
            <span>{{ item.name }}</span>
            <div class="d-flex align-center">
              <div class="badge">{{ item.type }}</div>
              <div class="point"></div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-md-none jobs m-jobs">
        <v-col
          class="box3-wrapper"
          cols="12"
          md="6"
          v-for="(item, index) in jobs"
          :key="index"
          @click="MobileJobClickHandler(item)"
        >
          <div class="box3" :class="{ active: item.open }">
            <div class="close-btn" :class="{ active: item.open }">
              <div class="line line-1"></div>
              <div class="line line-2"></div>
            </div>
            <div class="d-flex align-center">
              <span class="job-title">{{ item.name }}</span>
              <div class="point"></div>
            </div>
            <div class="details">
              <div class="block" v-if="item.responsibility.length > 0">
                <p>岗位职责：</p>
                <ol>
                  <li v-for="(item, index) in item.responsibility" :key="index">
                    {{ item }}
                  </li>
                </ol>
              </div>
              <div class="block" v-if="item.demand.length > 0">
                <p>岗位要求：</p>
                <ol>
                  <li v-for="(item, index) in item.demand" :key="index">
                    {{ item }}
                  </li>
                </ol>
              </div>
              <div class="block" v-if="item.extra.length > 0">
                <p>加分项：</p>
                <ol>
                  <li v-for="(item, index) in item.extra" :key="index">
                    {{ item }}
                  </li>
                </ol>
              </div>
              <div class="block" v-if="item.desc.tip">
                <p>职位描述：</p>
                <div style="margin-bottom: 12px">{{ item.desc.tip }}</div>
                <ol>
                  <li v-for="(item, index) in item.desc.list" :key="index">
                    {{ item }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="row">
        <v-col cols="12" class="bottom">
          <div class="box1 contact-wrapper">
            <div class="contact">
              <img
                src="@/assets/images/icon-email.png"
                alt=""
              />加入我们：kun.wang@nenly.cn
            </div>
            <div class="contact">
              <img
                src="@/assets/images/icon-bd.png"
                alt=""
              />商务合作：BD@nenly.cn
            </div>
            <div class="contact">
              <img
                src="@/assets/images/icon-phone.png"
                alt=""
              />联系电话：136 8329 7673
            </div>
            <div class="contact">
              <img
                src="@/assets/images/icon-addr.png"
                alt=""
              />⁣公司地址：北京市朝阳区望京融科中心
            </div>
          </div>
          <div class="foot-msg">
            <span
              style="color: #ffffff; text-decoration: none; cursor: pointer"
              @click="agreementDialog = true"
              >隐私协议</span
            ><br />
            <a href="https://beian.miit.gov.cn" target="_blank">京ICP备18021254号</a>
            <br />
            京公网安备 11010502038680号
            <br />Copyright©
            2020 北京念力科技有限公司版权所有
          </div>
        </v-col>
      </div>
    </div>
    <v-dialog
      v-model="agreementDialog"
      max-width="1124px"
      overlay-color="#333333"
    >
      <v-card style="padding: 24px">
        <h1>念力科技隐私政策</h1>
        <br />
        本政策最新修改日期：2021年11月16日 <br />
        本政策生效日期：2021年07月21日 <br /><br />
        感谢您选择和使用念力科技服务。<br />
        本隐私政策适用于北京念力科技有限公司（以下简称“我们”）提供的服务及其延伸的功能（以下简称“念力科技服务”），如我们提供的某款产品有单独的隐私政策或条款，则该产品的隐私政策或条款将优先适用；该款产品隐私政策或条款未涵盖的内容，以本隐私政策内容为准。<br /><br />
        我们一直重视保护用户（以下简称“您”）的个人信息及隐私，依据本《念力科技隐私政策》（以下简称“本政策”）的规定为您提供服务。在您使用念力服务时，为了履行法定义务，或者为了向您提供服务所必需，或者为了向您提供优质的服务、不断优化用户体验以及保障您的账户安全，我们可能会处理（包括收集、存储、使用、提供等）您的个人信息。通过本政策，您可以了解我们如何处理您的个人信息、您在个人信息处理活动中的各项权利，以及您如何实现这些权利。需要特别说明的是，本隐私政策不适用于展示在、链接到或再封装我们的服务的那些适用其他的隐私政策、由第三方提供的服务。虽然第三方展示在、链接到或再封装我们的服务，但我们并不了解或控制其行为，因此也不为其行为负责；在您已查看并接受其隐私政策之前，请谨慎访问或使用其服务。如您允许我们与第三方网站共享的信息，则同时需受第三方网站服务条款和隐私政策的约束。<br /><br />
        本声明与您所使用的念力科技服务息息相关，希望您仔细阅读。您以在线勾选等任何方式确认接受本声明或使用念力科技服务的，即视为您已充分阅读、理解并同意接受本声明的约束，本声明即在您与腾讯云之间产生法律效力。<br /><br />
        本隐私政策将帮助您了解以下内容：<br />
        1．适用范围 <br />
        2．我们如何收集和使用您的个人信息 <br />
        3．我们如何共享、转让、公开披露您的个人信息 <br />
        4．Cookie和网络Beacon的使用 <br />
        5．我们如何保存您的个人信息 <br />
        6．我们如何保护您的个人信息 <br />
        7．您的权利 <br />
        8．未成年人信息 <br />
        9．您的个人信息如何进行跨境转移 <br />
        10．隐私政策的修订及通知 <br />
        11．如何联系我们 <br /><br />
        <h2>一、适用范围</h2>
        1．本隐私政策适用于念力科技提供的所有服务。
        需要特别说明的是，本隐私政策不适用于除本隐私政策第四条约定经您确认的与第三方共享信息外其他第三方向您提供的服务。请您知悉：如果您浏览第三方网站或使用第三方产品、服务时向其提供个人信息，则您的信息应当适用该第三方的隐私政策，我们不承担任何法律责任，请您届时务必仔细阅读该第三方的隐私政策并确认是否接受。
        <br /><br />
        <h2>二、我们如何收集和使用您的个人信息</h2>
        个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别您的身份或者反映您的活动情况的各种信息。我们根据合法、正当、必要的原则，仅收集实现产品功能所必要的信息。<br />
        1．我们如何收集您的个人信息<br />
        （1）获取及使用念力科技各项功能涉及的个人信息
        <br />
        1）设备信息<br />
        当你获取及使用念力科技服务时，为了保障您正常使用念力科技服务，更准确定位并解决您在使用念力科技服务时遇到的问题，改进及优化念力科技的服务体验，保障您的帐号安全，我们可能会收集设备属性信息（例如您的硬件型号、操作系统版本、设备配置、唯一设备标识符、国际移动设备身份码IMEI、网络设备硬件地址MAC等）以及设备连接信息（浏览器的类型、电信运营商）。对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务。·
        <br />2）日志信息
        <br />与大多数互联网服务一样，我们的服务器会自动记录您在访问念力科技服务时所发出的请求，例如您的IP
        地址、浏览器的类型、硬件设备信息、操作系统的版本、网络运营商的信息、您访问服务的日期、时间、时长等。请注意，这是我们为了保障软件与服务的正常运行所要收集的基本信息。
        <br />3）帐号注册信息
        当您使用念力科技的云游戏功能在线体验第三方运营的手机游戏时，您可能需要注册或登录您的游戏账号，并且第三方手机游戏运营方可能收集、使用您的相关信息，以便您使用其提供的服务。请您放心，念力科技并不会收集、使用任何您的游戏账号信息或其他您提供给第三方手机游戏运营方的信息。关于第三方手机游戏运营方如何收集、使用您的个人信息，建议您参考第三方手机游戏运营方的相关服务协议及隐私政策。
        <br />4）第三方帐号登录信息
        您可以使用第三方帐号（如微信、QQ）登录念力科技服务。此时，您将授权念力科技读取您在该第三方帐号注册的公开信息（如昵称、头像）。
        <br />（2）事先征得同意的例外
        <br />1）与个人信息控制者履行法律法规规定的义务相关的；
        <br />2）与国家安全、国防安全直接相关的；
        <br />3）与公共安全、公共卫生、重大公共利益直接相关的；
        <br />4）与刑事侦查、起诉、审判和判决执行等直接相关的；
        <br />5）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；
        <br />6）所涉及的个人信息是个人信息主体自行向社会公众公开的；
        <br />7）根据个人信息主体要求签订和履行合同所必需的；
        <br />8）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
        <br />9）维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障；
        <br />10）个人信息控制者为新闻单位，且其开展合法的新闻报道所必需的。
        <br />（3）匿名化处理
        对于我们收集到的您的个人信息，我们将通过技术手段及时进行匿名化处理。您同意并认可，在我们不泄露您个人信息的前提下，我们有权对匿名化处理后的用户数据进行分析、挖掘和利用，有权对念力科技产品的使用情况进行统计分析并用于可能的第三方信息共享。
        <br />2．我们如何使用您的个人信息
        <br />我们使用收集来的您的个人信息是为了向您提供服务及提升服务质量，为了实现这一目的，我们会把您的信息用于下列用途：
        <br />（1）向您提供念力科技各项服务，并维护、改进这些服务。
        <br />（2）为提高您使用我们服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或念力科技相关协议、规则的情况，我们可能使用您的会员信息、并整合设备信息、有关网络日志以信息，来判断您账户及交易风险、进行身份验证、安全事件的检测及防范，并依法采取必要的记录、审计、分析、处置措施。
        <br />（3）用于提升个性化服务。
        <br />（4）联系您解决与您使用念力科技相关的问题。
        <br />（5）经您许可的其他用途。 <br /><br />
        <h2>三、我们如何共享、转让和公开披露您的个人信息</h2>
        我们会以高度的勤勉义务对待您的信息，除非获得您的明确同意，我们不会与本公司以外的任何公司、组织和个人分享您的个人信息，但经过处理无法识别特定个人且不能复原的除外。
        <br />1．共享
        <br />我们对您的信息承担保密义务，不会为满足第三方的营销或非法目的而向其出售或出租您的任何信息，但是下列情形除外：
        <br />1.1 事先获得您的明示同意或授权。 <br />1.2
        根据法律法规的规定、诉讼、仲裁解决需要，或应行政或司法机构的要求，向第三方或者行政机关、司法机构、执法机构或监管机构披露。
        <br />1.3
        为了防止欺诈、危害安全等事故发生，保护您及其他社会公众的合法权益，在我们提供服务时，会将您的信息用于身份验证、客户服务、安全防范、诈骗监测用途。
        <br />1.4
        获得您的明示同意或授权后，向念力科技的关联方或合作伙伴分享您的个人信息。
        为了适配在不同手机设备上使用念力科技以及第三方平台服务，实现您的手机设备身份认证、手机设备安全、登录第三方平台账号、通过第三方平台分享信息以及支付安全，念力科技可能接入了第三方SDK。上述第三方SDK提供方包括手机通讯运营商和第三方支付机构。部分第三方SDK可能会调用您的设备权限、获取您的相关信息（包括但不限于IMEI、IMSI、设备MAC地址、软件列表、设备序列号、Android
        ID），以便您在不同的手机设备或第三方平台上正常使用相应功能。不同的第三方SDK所调用的设备权限类型以及所获得的信息可能不同，可能包括获取您的位置信息、读/写您的外部存储卡、读取您的手机状态和身份、查看WLAN连接、检索正在运行的应用/蓝牙。关于第三方SDK具体调用的设备权限类型，以及如何收集、使用您的个人信息，建议您参考第三方SDK的相关服务协议及隐私政策。
        <br /><br />
        <v-simple-table class="v-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">功能类型</th>
                <th class="text-left">SDK名称</th>
                <th class="text-left">第三方公司名称</th>
                <th class="text-left">使用目的</th>
                <th class="text-left">申请调用的权限</th>
                <th class="text-left">收集个人信息类型</th>
                <th class="text-left">官网链接</th>
                <th class="text-left">隐私政策链接</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="2">支付相关</td>
                <td>微信支付SDK</td>
                <td>深圳市腾讯计算机系统有限公司</td>
                <td>帮助用户使用微信支付功能</td>
                <td>写入外部存储卡；修改声音设置信息</td>
                <td>无</td>
                <td>
                  <a href="https://pay.weixin.qq.com/index.php/core/home"
                    >https://pay.weixin.qq.com/index.php/core/home</a
                  >
                </td>
                <td>
                  <a href="https://pay.weixin.qq.com/index.php/core/home"
                    >https://pay.weixin.qq.com/index.php/core/home</a
                  >
                </td>
              </tr>
              <tr>
                <td>支付宝支付SDK</td>
                <td>蚂蚁金服（杭州）网络技术有限公司</td>
                <td>帮助用户使用支付宝服务</td>
                <td>查看WLAN连接；检索正在运行的应用</td>
                <td>Wi-Fi地址</td>
                <td>
                  <a href="https://www.alipay.com">https://www.alipay.com</a>
                </td>
                <td>
                  <a href="https://render.alipay.com/p/c/k2cx0tg8"
                    >https://render.alipay.com/p/c/k2cx0tg8</a
                  >
                </td>
              </tr>
              <tr>
                <td>分享登录相关</td>
                <td>微信开放平台SDK</td>
                <td>腾讯科技（深圳）有限公司</td>
                <td>帮助用户使用微信分享、登录、收藏、支付等功能</td>
                <td>读取外置存储卡；写入外部存储卡</td>
                <td>设备信息</td>
                <td>
                  <a href="https://open.weixin.qq.com"
                    >https://open.weixin.qq.com</a
                  >
                </td>
                <td>
                  <a
                    href="https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy"
                    >https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy</a
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <br />2．转让
        我们不会将您的用户信息转让给任何公司、组织和个人，但以下情况除外：
        <br />
        2.1
        在获取明确同意的情况下转让：获得您的明确同意后，我们有权向其他方转让您的用户信息；
        <br />2.2
        在念力科技与其他法律主体者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到用户信息转让，我们会告知并征得您的同意，并要求新的持有您用户信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
        <br />3．公开披露 我们仅会在以下情况下，公开披露您的用户信息：<br />
        3.1
        获得您明确同意或基于您的主动选择，我们可能会公开披露您的用户信息；<br />
        3.2
        基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
        <br />3.3 共享、转让、公开披露用户信息时事先征得授权同意的例外
        以下情形中，共享、转让、公开披露您的用户信息无需事先征得您的授权同意：
        <br />3.3.1 与国家安全、国防安全有关的；<br />
        3.3.2 与公共安全、公共卫生、重大公共利益有关的；<br />
        3.3.3 与犯罪侦查、起诉、审判和判决执行等有关的；<br />
        3.3.4
        出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
        <br />3.3.5 您自行向社会公众公开的个人信息；<br />
        3.3.6
        从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
        <br />4．用户业务数据和公开信息
        不同于您的用户信息，对于用户业务数据和公开信息，念力科技将按如下方式处理：
        <br />4.1．用户业务数据 <br />4.1.1
        您通过念力科技提供的服务，加工、存储、上传、下载、分发以及通过其他方式处理的数据，均为您的用户业务数据，您完全拥有您的用户业务数据。我们只会严格执行您的指示处理您的业务数据，除按与您协商一致或执行明确的法律法规要求外，不对您的业务数据进行任何非授权的使用或披露。
        <br />4.1.2
        您应对您的用户业务数据来源及内容负责，念力科技提示您谨慎判断数据来源及内容的合法性。因您的用户业务数据内容违反法律法规、部门规章或国家政策而造成的全部结果及责任均由您自行承担。
        <br />4.1.3
        念力科技存储的用户业务数据。念力科技恪守对用户的安全承诺，根据适用的法律保护用户存储在念力科技的数据。
        <br />4.2．公开信息 <br />4.2.1
        公开信息是指您自行公开分享的任何信息，任何人都可以在使用和未使用念力科技服务期间查看或访问这些信息。
        <br />
        <br />
        <h2>四、Cookie和网络Beacon的使用</h2>
        1．为使您获得更轻松的访问体验，您使用念力科技提供的服务时，我们可能会通过小型数据文件识别您的身份，帮您省去重复输入注册信息的步骤，或者帮助判断您的账户安全。这些数据文件可能是Cookie、Flash
        Cookie，或您的浏览器或关联应用程序提供的其他本地存储（以下合称“Cookie”）。
        <br />2．请您理解，我们的某些服务只能通过使用Cookie才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以打开浏览器的设置选项，选择隐私设置，勾选“不跟踪”，或者禁止cookie，这样您的数据将不会被我们追踪到，但这一举动在某些情况下可能会影响您安全访问念力科技提供的服务。
        <br />3．网页上常会包含一些电子图象（称为“单像素GIF 文件”或 “网络
        Beacon”），使用网络Beacon可以帮助网站计算浏览网页的用户或访问某些Cookie，我们会通过网络Beacon收集您浏览网页活动的信息，例如您访问的页面地址、您先前访问的援引页面的位址、您停留在页面的时间、您的浏览环境以及显示设定等。
        <br /><br />
        <h2>五、我们如何保存您的个人信息</h2>
        我们在中华人民共和国境内运营中收集和产生的个人信息，原则上存储在中国境内，并按照中国中华人民共和国法律法规、部门规章等规定的存储期限存储您的个人信息。
        <br />1．保存期限
        <br />您在使用念力科技产品及服务期间，我们将持续为您保存您的个人信息。如果您注销帐户或主动删除上述信息，我们将依据网络安全法等法律法规规定保存您的信息。在您注销帐户或主动删除上述信息后，我们不会再对您的个人信息进行商业化使用，并在合理的期限内删除或匿名化处理您的个人信息。
        <br />2．保存地域
        <br />您的个人信息均储存于中华人民共和国境内。如我们需要向境外传输您的个人信息，我们会严格按照法律法规的规定执行，并保证您的个人信息安全。
        <br /><br />
        <h2>六、我们如何保护您的个人信息</h2>
        1．念力科技非常重视您的信息安全。我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的用户信息。防止用户信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会使用加密技术提高用户信息的安全性；我们会使用受信赖的保护机制防止用户信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问用户信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护用户信息重要性的认识。
        <br />2．我们收集的信息保存于不向公众开放的安全运行环境之中。为避免未经授权访问您的信息，我们将其保存在有防火墙保护并可能加密的服务器之中。但是，没有任何系统是绝对安全的，请您理解在信息网络上不存在“完善的安全措施”。即使我们做出了很大努力，仍有可能无法杜绝对您的个人信息的非法访问。此外，由于无法判断他人的陈述是否属实，我们可能会被不可靠的、有误导性的或非法的信息所蒙蔽。
        <br />3．我们会建立专门的管理制度、流程和组织确保信息安全，会对员工进行数据安全的意识培养和安全能力的培训和考核，加强员工对于保护个人信息重要性的认识，严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审查。
        <br />4．我们提醒您注意，互联网并非绝对安全的环境，当您通过社交软件与其他用户交互信息时，不确定第三方软件对信息的传递是否完全加密，请注意确保您个人信息的安全，我们建议您不要通过此类方式发送个人信息，以免个人信息泄露。请使用复杂密码，协助我们保证您的帐号以及个人信息安全。
        <br />5．我们也请您理解，在互联网行业由于技术的限制和飞速发展以及可能存在的各种恶意攻击手段，即便我们竭尽所能加强安全措施，也不可能始终保证信息的百分之百安全。请您了解，您使用我们的产品和/或服务时所用的系统和通讯网络，有可能在我们控制之外的其他环节而出现安全问题。
        <br />6．若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，并以推送通知、公告等形式告知您。
        <br />7．我们会制定网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施，并按照规定向有关主管部门报告。
        <br />8．在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处理措施、您可自主防范和降低的风险的建议、对您的补救措施等。我们将及时将事件相关情况以站内通知、短信通知、电话或邮件等您预留的联系方式告知您，难以逐一告知时我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
        <br /><br />
        <h2>七、您的权利</h2>
        按照中国相关的法律、法规、标准，我们保障您对自己的个人信息行使以下权利：
        <br />1. 访问、变更您的个人信息
        <br />当您发现我们处理的关于您的个人信息有错误时，您有权对错误或不完整的信息作出更正，经对您的身份进行验证后，您可以通过contact@nenly.cn将您的更正申请提交给我们。
        <br />2. 改变您授权同意的范围
        <br />您可以随时给予或收回您的授权同意。您可以通过contact@nenly.cn将您的更正申请提交给我们。当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理。
        <br />3. 删除您的个人信息
        <br />在以下情形中，您可以向我们提出删除个人信息的请求，您可以通过contact@nenly.cn随时与我们联系：当您从我们的服务中删除信息后，我们可能不会立即在备份系统中删除相应的信息，但会在备份更新时删除这些信息。
        <br />4. 响应您的上述请求
        <br />为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。如您不满意，您可以通过contact@nenly.cn联系我们。
        对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
        <br /><br />
        <h2>八、未成年人保护</h2>
        我们重视未成年人的个人信息保护。如您为未成年人，建议您请您的监护人仔细阅读本政策，并在征得您的监护人同意的前提下使用我们的服务或向我们提供信息。我们将仅在法律法规的允许、监护人的明示同意或为保护未成年人必要的情况下收集、使用或披露未成年人个人数据。
        <br /><br />
        <h2>九、您的个人信息如何进行跨境转移</h2>
        我们收集的您的个人信息将存储在中国境内，如涉及跨境我们会严格按照法律法规的规定执行，开展数据出境安全评估并保证您的个人信息安全。我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。
        <br /><br />
        <h2>十、本隐私政策的修订及通知</h2>
        我们可能随时会对本隐私政策进行调整或变更，未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上或站内信或向您发送电子邮件或其他合适的能触达您的方式通知您。若您不同意该等变更可以停止使用念力科技产品和服务，若您继续使用我们的产品和/或服务，即表示您同意受修订后的本隐私政策的约束。
        <br /><br />
        <h2>十一、如何联系我们</h2>
        感谢您对念力科技服务的信任与支持，我们非常欢迎您对我们服务问题进行反馈。您可以通过contact@nenly.cn联系我们反馈您对念力科技服务的建议以及在使用过程中遇到的问题，以帮助我们优化产品功能及服务，使更多用户更加便捷的使用我们的产品和服务。如果您对本个人信息保护政策有任何疑问、意见或建议，您还可以通过如下联络方式同我们联系：
        <br />联系地址：北京市朝阳区融科望京中心A座1502
        <br />联系方式：contact@nenly.cn
        <br />为保障我们高效处理您的问题并及时向您反馈，需要您提交身份证明、有效联系方式和书面请求及相关证据，我们会在验证您的身份后处理您的请求。一般情况下，我们将在验证您的身份后【15】个工作日内回复。
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'About',
  data() {
    return {
      agreementDialog: false,
      jobDialog: false,
      active: -1,
      job: {
        name: '',
        type: '',
        responsibility: [],
        demand: [],
        desc: {
          tip: '',
          list: [],
        },
        open: false,
      },
      jobs: [
        {
          open: false,
          name: 'Go开发工程师',
          type: '研发类',
          responsibility: [
            '深入理解业务架构和需求，使用GO语言对云游戏业务的服务端进行开发；',
            '调研各种技术方案，对自己独立的模块从设计到开发完全负责，有自己的想法和发言权，不做码农；',
            '参与到基础平台的架构设计和核心模块代码编写，持续提升核心底层在高并发环境下的高处理性能。',
          ],
          demand: [
            '统招一本以上学历，计算机相关专业优先，1年以上后端开发经验（优秀的应届生亦可）；',
            '基础知识扎实，对数据结构、基本算法、网络、操作系统等有较好的掌握；',
            '有强烈的求知欲、好奇心和进取心，对自己代码输出的品质负责。',
            '加分项，如果掌握一项就会有明显加分：',
            '熟悉至少一种go语言web开源框架（gin、gorilla、beego、martini、iris、revel、echo等），并有相关编程经验；',
            '有Kubernetes或docker容器化经验，在大型分布式、高并发、高性能、高可用系统设计和开发方面有较多的实际经验；',
            '关注前沿技术，有开源贡献。',
          ],
          extra: [
            '熟悉至少一种go语言web开源框架（gin、gorilla、beego、martini、iris、revel、echo等），并有相关编程经验；',
            '有Kubernetes或docker容器化经验，在大型分布式、高并发、高性能、高可用系统设计和开发方面有较多的实际经验；',
            '关注前沿技术，有开源贡献。',
          ],
          desc: {},
        },
        {
          open: false,
          name: '后端开发工程师',
          type: '研发类',
          responsibility: [
            '参与并且协助平台的相关开发，以及系统功能优化和性能调优；',
            '协助项目技术调研与选型，设计项目架构，编写相关文档；',
            '协助项目版本开发迭代和维护，保证可维护性和代码性能。',
          ],
          demand: [
            '熟悉任一后端语言，比如：Java，PHP，GoLang，Spring框架等',
            '会使用Git进行代码管理',
            '熟悉一种或多种关系型或非关系型数据库',
            '具备扎实的计算机基础知识（算法数据结构、计算机网络、操作系统、数据库原理、计算机体系结构等）',
            '加分项：',
            '基本的读、写英文文档能力',
            '对计算机技术有好奇心，具备强烈的学习意愿',
            '开源社区或有个人作品优先',
          ],
          extra: [
            '基本的读、写英文文档能力',
            '对计算机技术有好奇心，具备强烈的学习意愿',
            '开源社区或有个人作品优先',
          ],
          desc: {},
        },
        {
          open: false,
          name: '底层系统开发工程师',
          type: '研发类',
          responsibility: [
            '参与项目需求分析，进行AndroidFramework相关模块的设计、开发、调试、测试等；',
            'Android系统的优化和系统稳定性维护；',
            '支持应用软件工程师和系统软件工程师开发、调试。',
          ],
          demand: [
            '计算机专业知识扎实，熟悉Linux系统，熟悉常用数据结构和算法，具有优秀的软件开发习惯；',
            '同时熟悉C/C++语言和Java语言，至少精通其中一项语言；',
            '至少2年framework工作经验，熟悉ApplicationFramework优先考虑；',
            '良好的英语读写能力，良好的快速分析和问题解决能力；',
            '熟悉Android系统框架；',
            '责任心强,优秀的团队合作及沟通能力。',
          ],
          extra: [],
          desc: {},
        },
        {
          open: false,
          name: '技术美术',
          type: '游戏与运营',
          responsibility: [
            '研究并提出工具及流程优化需求以帮助游戏项目中的艺术开发;',
            '熟悉并制定符合需求的美术资源管理及流程规范;',
            '负责全面了解游戏美术要求和问题的各个方面，并提供适当的解决方案',
            '负责达到更好的游戏美术效果并创建及时有效的技术研究;',
            '负责与开发人员进行有效沟通，以提高游戏效率和性能，在高/中/低配置中的整体外观并降低低性能阈值。',
          ],
          demand: [
            '至少5年的游戏开发经验，至少2年的技术美术经验;',
            '具有较强的审美理解能力和专业水平的艺术能力，有开发大型下一代游戏项目的经验;',
            '能够与游戏设计师和工程师进行协调，以创建有助于解决艺术与技术之间问题;',
            '对Shader逻辑和结果改进有深入的了解，在PBR技术和Cel-shading方面拥有丰富的研究经验和理解;',
            '精通3D艺术创作软件，包括：SP，3DMax，Maya，ZBrush，XSI，Photoshop等;',
            '有自驱力，同时有一定管理能力。',
          ],
          desc: {},
          extra: [],
        },
        {
          open: false,
          name: '海外投放经理',
          type: '市场商务类',
          responsibility: [
            '按照负责品类制定投放策略，完成游戏在Facebook/ Google等渠道的广告投放，并进行相应的优化；',
            '在控制成本保证广告收益的前提下完成投放任务，提升ROI；',
            '通过对相关数据及竞争对手的分析，不断优化方案和素材，持续优化投放策略；',
            '整合并优化配置组内资源，在不同阶段合理调配投放工作安排，并协调好部门间的协作；',
            '主导组内投放素材创意和数据分析汇总，持续提高团队内优化师的投放工作能力。',
          ],
          demand: [
            '统招本科及以上理工科专业，1年以上互联网或游戏行业经验，热爱游戏；',
            '熟悉广告投放、竞价流程，有海外休闲手游广告投放经验优先；',
            '对数字敏感，擅长数据分析；',
            '逻辑性强，主动性强，并具有高度责任心，能适应快速的工作节奏；',
            '良好沟通能力及团队协作精神；',
            ' 英语流利优先。',
          ],
          extra: [],
          desc: {},
        },
        {
          open: false,
          name: '游戏制作人',
          type: '游戏与运营',
          responsibility: [],
          demand: [],
          extra: [],
          desc: {
            tip: '寻找志同道合的小伙伴，一起深度开发云游戏的可能性',
            list: [
              '熟悉海外市场，有成功的海外项目经验；',
              '对一个或多个品类有深刻的理解和积累；',
              '成熟的管理和落地能力；',
              '对游戏充满热情，有责任心。',
            ],
          },
        },
        {
          open: false,
          name: '高级海外投放经理',
          type: '市场商务类',
          responsibility: [
            '深入了解海外市场，制定广告投放策略并落地执行；',
            '分析各渠道投放数据，根据分析结果提出策略调整和修改建议；',
            '研究竞争对手的投放策略，学习并分享给团队；',
            '实时了解各渠道的新功能，与时俱进，与团队共同研究最新技术；',
            '定期和团队一起总结广告投放效果，优化广告创意。',
          ],
          demand: [
            '熟悉各主要广告平台，具备丰富的全球广告投放经验，参与过百万级（美金）月流水产品的全球广告投放优先，有带团队经验优先；',
            '有较强的数据分析、广告战略决策、统筹规划、分析问题和解决问题、组织和执行力，能熟练运用自己技能引导团队；',
            '结果导向，勇于突破，负责敬业，能承受较大的工作压力；',
            '品行佳，团队合作和协作精神良好；',
            '有游戏的项目背景或者兴趣者优先。',
          ],
          desc: {},
          extra: [],
        },
        {
          open: false,
          name: '游戏投放视频设计',
          type: '市场商务类',
          responsibility: [
            '负责在推产品的广告素材制作，主要包括视频，GIF图，轮番图及常规广告宣传图；',
            '为海外广告投放构思创意；',
            '广告创意效果分析；',
            '竞品创意搜集和分析。',
          ],
          demand: [
            '熟练使用PS、AE,会使用1到2种视频制作软件；',
            '有设计功底，会运用手绘版，板绘能力强，对广告创意感兴趣；',
            '责任心强，执行能力强；',
            '动画专业优先，有插画功底优先。',
          ],
          desc: {},
          extra: [],
        },
        {
          open: false,
          name: '数据运营',
          type: '游戏与运营',
          responsibility: [
            '构建并完善游戏项目运营数据分析体系，提供日常游戏业务相关的数据支持，包括但不限于游戏产品分析、市场分析及舆情分析等；',
            '针对游戏版本内容定期进行数据监控及专项分析，包括并不限于用户价值、新增回流、游戏生态、活动版本效果分析、商业化分析等；',
            '针对游戏市场发行工作提供数据分析支持，包括但不限于宣发效果分析、投放监测及用户跟踪分析等；',
            '通过数据挖掘和探索分析用户行为数据及舆情数据等，为游戏设计和产品设计提供指导；',
            '负责用户行为数据指标体系及相关数据平台搭建，研究游戏的用户精细化运营，如用户画像分析、行为分析等。',
          ],
          demand: [
            '2年以上工作经验，至少2年以上从事游戏数据分析工作，对数据分析感兴趣；',
            '对数据敏感，熟悉数据收集、数据分析方法，掌握数据建模基本原理和数据挖掘算法，了解服务器构架模式、数据库操作；',
            '拥有手游完整项目数据分析经验，能够独立开展研究项目；',
            '具备良好的逻辑思维能力和分析能力，具有良好的团队协作能力。',
          ],
          desc: {},
          extra: [],
        },
        {
          open: false,
          name: '海外市场品牌专员',
          type: '市场商务类',
          responsibility: [
            '根据市场情况、产品类型、产品生命周期等制定发行方案，并跟进执行，总结数据；',
            '负责各项市场活动，制定完整策略并推进执行，追踪实施状况及效益分析；',
            '协助领导完成公司产品品牌整合。包括线上线下活动品牌化、版本推广创意执行、跨界联动内容，产品周边衍生内容等；',
          ],
          demand: [
            '1. 本科以上学历，有1年以上海外游戏发行经验；',
            '了解游戏行业发展走向，洞悉市场发展动态；熟悉游戏行业海外市场推广模式，能理解当地玩家特性，洞察玩家需求；',
            '具备良好的跨部门沟通和合作能力、洞察力和逻辑分析能力，善于思考总结；',
            '英语能力优秀优先，热爱游戏优先。',
          ],
          desc: {},
          extra: [],
        },
        {
          open: false,
          name: '社区运营',
          type: '游戏与运营',
          responsibility: [
            '负责游戏产品社区运营工作；',
            '负责运营产品的排期制定、版本更新，活动等日常维护工作；',
            '负责运营产品的内部协调，与各部门之间的接口与沟通工作，保证产品的上线与稳定运营；',
            '负责产品运营数据分析、产出分析报告并对产品提出改进建议；',
            '收集与整理海外游戏市场信息与产品信息；',
            '负责客诉，用户体验与版本相关的问题收集，整理、反馈与解决。',
          ],
          demand: [
            '热爱游戏，工作细心，具抗压性；',
            '性格开朗，具备良好的团队合作精神与沟通协调能力；',
            '具备一定的英语能力者优先；',
            '2年以上游戏行业经验；',
            '对产品数据、活动效果类数据敏感，具备良好的项目管理能力，具有创意与应变能力者优先；',
            '较强的抗压性，可弹性配合游戏运营加班者优先。',
          ],
          desc: {},
          extra: [],
        },
      ],
    }
  },
  methods: {
    MobileJobClickHandler(item) {
      item.open = !item.open
    },
    jobClickHandler(item) {
      this.job = item
      this.jobDialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
.v-table {
  th {
    padding: 4px 8px !important;
  }
  td {
    min-width: 100px;
    max-width: 140px;
    font-size: 12px !important;
    padding: 4px 8px !important;
  }
}
// about us
.about-us {
  .about {
    margin: 0 auto;
  }
  .h-title {
    font-size: 28px;
    text-shadow: 0 0 4px rgba(255, 255, 255, 0.4);
    margin-bottom: 24px;
    text-align: left;
  }
  .box1 {
    position: relative;
    background: linear-gradient(209.62deg, #212121 13.78%, #2b2b2b 83.18%);
    opacity: 0.9;
    box-shadow: 0px 7px 57px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    padding: 30px;
    font-size: 16px;
    border: 4px solid #262626;
    box-sizing: border-box;
    margin: 40px auto;
    .tip {
      position: absolute;
      top: -70px;
      right: -80px;
      width: 260px;
    }
  }
  .join {
    position: relative;
    font-size: 20px;
    font-weight: bold;
    img {
      position: absolute;
      top: 0;
      right: -12px;
      width: 194px;
    }
  }
  .jobs {
    padding: 0;
    .title-blue {
      color: #5fb6b9;
      font-size: 28px;
      font-weight: bold;
      text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.4);
    }
    .h-subtitle {
      font-size: 16px;
      font-weight: bold;
      margin: 28px 0 12px;
    }
    ol {
      font-size: 13px;
      line-height: 1.5;
    }
  }

  .jobs {
    .box3-wrapper {
      padding: 5px 12px;
    }
    .box3 {
      background: linear-gradient(209.62deg, #212121 13.78%, #2b2b2b 83.18%);
      box-shadow: 0 7px 57px rgba(0, 0, 0, 0.4);
      border-radius: 4px;
      border: 4px solid #262626;
      padding: 20px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
      .badge {
        background-color: rgba($color: #fff, $alpha: 0.1);
        border-radius: 2px;
        padding: 2px 8px;
        font-size: 16px;
        color: #ccc;
      }
      .point {
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background: #5fb6b9;
        filter: blur(1px);
        margin-left: 24px;
      }
    }
  }

  .m-jobs {
    .box3-wrapper {
      padding: 5px 0;
    }
    .box3 {
      position: relative;
      display: block;
      padding: 14px 24px;
      font-size: 12px;
      max-height: 66px;
      overflow: hidden;
      transition: max-height 0.2s ease-in-out;
      &.active {
        max-height: 999px;
      }
      .close-btn {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        transition: transform 0.2s linear;
        &.active {
          transform: rotate(45deg);
        }
        .line {
          border-radius: 2px;
          background-color: #fff;
        }
        .line-1 {
          width: 14px;
          height: 2px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .line-2 {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 2px;
          height: 14px;
        }
      }
      .job-title {
        font-size: 20px;
        font-weight: bold;
      }
      .point {
        width: 14px;
        height: 14px;
        margin-left: 14px;
      }
      .block {
        margin-top: 20px;
        li {
          font-size: 12px;
        }
        p {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    .foot-msg {
      font-size: 12px;
      opacity: 0.5;
      padding-right: 12px;
    }
    .contact-wrapper {
      padding: 8px 12px;
      margin: 0;
    }
    .contact {
      font-size: 14px;
      display: flex;
      align-items: center;
      line-height: 2;
      img {
        width: 15px;
        margin-right: 8px;
        vertical-align: middle;
      }
    }
  }
  .member {
    position: relative;
    .m-tip {
      position: absolute;
      left: -60px;
      bottom: -60px;
      width: 260px;
    }
    .col {
      padding-left: 88px;
    }
    .col-border {
      border-right: 1px solid rgba(119, 118, 118, 0.4);
      padding-left: 32px;
    }
    .m-title {
      font-size: 30px;
      font-weight: bold;
      display: flex;
      align-items: center;
      .badge {
        display: inline-block;
        font-size: 32px;
        border-radius: 4px;
        padding: 0 8px;
        height: 36px;
        line-height: 36px;
        margin-right: 18px;
        background: #36aee1 !important;
        &.blue {
          background: #366ae1 !important;
        }
      }
    }
    .m-row {
      display: flex;
      align-items: center;
      font-size: 18px;
      margin-top: 24px;
      img {
        width: 50px;
        margin-right: 20px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .about-us {
    .about {
      padding: 18px;
    }
    .h-title {
      font-size: 20px;
      text-align: center;
      &.title-join {
        font-size: 32px;
      }
    }
    .box1 {
      .tip {
        position: absolute;
        top: -50px;
        left: -50px;
        width: 160px;
      }
    }
    .join {
      font-size: 16px;
      font-weight: normal;
      .h-title {
        text-align: left;
      }
      img {
        width: 144px;
        top: -30px;
      }
    }
    .jobs {
      padding: 12px;
      .title-blue {
        font-size: 20px;
      }
    }
    .bottom {
      display: block;
      margin-top: 0;
      padding: 12px;
      width: 100%;
      .foot-msg {
        font-size: 12px;
        opacity: 0.5;
        padding-right: 12px;
        text-align: center;
        margin-top: 24px;
      }
      .contact-wrapper {
        padding: 8px 12px;
        margin: 0;
      }
      .contact {
        font-size: 14px;
        img {
          height: 12px;
          margin-right: 8px;
          vertical-align: middle;
        }
      }
    }
    .member {
      .m-tip {
        position: absolute;
        top: -40px;
        left: -36px;
        width: 130px;
      }
      .col {
        padding-left: 0;
      }
      .col-border {
        border-right: none;
        border-bottom: 1px solid rgba(119, 118, 118, 0.4);
      }
      .m-title {
        font-size: 20px;
        .badge {
          font-size: 20px;
          height: 30px;
          line-height: 30px;
        }
      }
      .m-row {
        font-size: 14px;
        font-weight: bold;
        img {
          width: 32px;
        }
      }
    }
  }
}
</style>
