<template>
  <v-container class="wrapper productions" id="Productions">
    <v-row class="align-center">
      <v-col cols="12" md="6" class="left">
        <div class="box">
          <h1 class="h-title">念力云游戏</h1>
          <h2 class="h-subtitle">
            念力提供一站式混合多云的云游戏全套解决方案，包括手机云游戏、试玩及可玩广告，
            可灵活部署全球主流公有云及私有云平台。
          </h2>
          <h2 class="h-subtitle">
            我们的解决方案助力中国游戏公司上云、出海，打造全新的互动体验。用户无需下载即点即玩，在任意终端随时随地体验精品游戏大作。
          </h2>
        </div>
      </v-col>

      <v-col cols="12" md="6" class="right">
        <v-img
          src="@/assets/images/productions.png?vuetify-preload"
          class="d-none d-md-block animate__animated animate__fadeIn"
        ></v-img>
        <v-img
          src="@/assets/images/m-productions.png?vuetify-preload"
          class="d-md-none animate__animated animate__fadeIn"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Productions',
}
</script>

<style lang="scss" scoped>
// productions
.productions {
  .left,
  .right {
    padding: 30px;
    box-sizing: border-box;
  }
  .h-title {
    text-align: left;
  }
  .h-subtitle {
    font-size: 20px;
    margin-top: 32px;
  }

  @media screen and(max-width: 600px) {
    .h-title {
      text-align: left;
    }
    .h-subtitle {
      font-size: 18px;
    }
  }
}
</style>
