<template>
  <v-container class="wrapper advantages" id="Advantages">
    <h1 class="h-title margin">核心优势</h1>
    <div class="box">
      <p class="box-title">混合云+多云</p>
      <v-row>
        <v-col cols="12" md="6" class="left">
          <img class="img" src="@/assets/images/partners.png" />
          <p class="box-content">
            自研技术一站式部署全球所有主流 X86 及 ARM
            架构公有云、私有云、裸金属服务器等获取最佳性能及资源。
          </p>
        </v-col>
        <div class="divider d-md-none"></div>
        <v-col cols="12" md="6" class="right">
          <v-img class="img" src="@/assets/images/img-ad.png"></v-img>
          <p class="box-content">
            技术架构无视底层硬件差异性， 可在任意终端设备无缝运行，
            包括智能汽车在内。
          </p>
        </v-col>
      </v-row>
    </div>
    <h1 class="h-title margin">基于公有云的优势</h1>
    <v-row class="d-none d-md-flex">
      <v-col cols="12" md="6" class="cols">
        <div class="box d-flex align-center">
          <img src="@/assets/images/Group 97704.png" alt="" />
          <div>
            <h2>全球化部署</h2>
            <h3>可迅速部署拓展，使业务遍及全球。</h3>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6" class="cols">
        <div class="box d-flex align-center">
          <img src="@/assets/images/Group 97843.png" alt="" />
          <div>
            <h2>按需弹性扩容</h2>
            <h3>灵活应对激增、高并发、海量访问。</h3>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6" class="cols">
        <div class="box d-flex align-center">
          <img src="@/assets/images/Group 97848.png" alt="" />
          <div>
            <h2>弹性低成本</h2>
            <h3>启动投入低，服务可按分钟计费。</h3>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6" class="cols">
        <div class="box d-flex align-center">
          <img src="@/assets/images/Group 97847.png" alt="" />
          <div>
            <h2>网络稳定</h2>
            <h3>带宽资源储备充足，保证游戏稳定性。</h3>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6" class="cols">
        <div class="box d-flex align-center">
          <img src="@/assets/images/Group 97846.png" alt="" />
          <div>
            <h2>支持边缘计算</h2>
            <h3>边缘基础设施完善，最大化降低游戏与玩家间的延迟。</h3>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6" class="cols">
        <div class="box d-flex align-center">
          <img src="@/assets/images/Group 97842.png" alt="" />
          <div>
            <h2>安全性高</h2>
            <h3>防御大流量 DDoS 攻击，智能安全防护等。</h3>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-md-none">
      <v-col cols="12" class="cols">
        <div class="box2">
          <div>
            <h2 class="box2-title">
              <div class="point"></div>
              全球化部署
            </h2>
            <h3>可迅速部署拓展，使业务遍及全球。</h3>
          </div>
          <img src="@/assets/images/Group 97704.png" alt="" />
        </div>
      </v-col>
      <v-col cols="12" class="cols">
        <div class="box2">
          <div>
            <h2 class="box2-title">
              <div class="point"></div>
              按需弹性扩容
            </h2>
            <h3>灵活应对激增、高并发、海量访问。</h3>
          </div>
          <img src="@/assets/images/Group 97843.png" alt="" />
        </div>
      </v-col>
      <v-col cols="12" class="cols">
        <div class="box2">
          <div>
            <h2 class="box2-title">
              <div class="point"></div>
              弹性低成本
            </h2>
            <h3>启动投入低，服务可按分钟计费。</h3>
          </div>
          <img src="@/assets/images/Group 97848.png" alt="" />
        </div>
      </v-col>
      <v-col cols="12" class="cols">
        <div class="box2">
          <div>
            <h2 class="box2-title">
              <div class="point"></div>
              网络稳定
            </h2>
            <h3>带宽资源储备充足，保证游戏稳定性。</h3>
          </div>
          <img src="@/assets/images/Group 97847.png" alt="" />
        </div>
      </v-col>
      <v-col cols="12" class="cols">
        <div class="box2">
          <div>
            <h2 class="box2-title">
              <div class="point"></div>
              支持边缘计算
            </h2>
            <h3>边缘基础设施完善，最大化降低游戏与玩家间的延迟。</h3>
          </div>
          <img src="@/assets/images/Group 97846.png" alt="" />
        </div>
      </v-col>
      <v-col cols="12" class="cols">
        <div class="box2">
          <div>
            <h2 class="box2-title">
              <div class="point"></div>
              安全性高
            </h2>
            <h3>防御大流量 DDoS 攻击，智能安全防护等。</h3>
          </div>
          <img src="@/assets/images/Group 97842.png" alt="" />
        </div>
      </v-col>
    </v-row>
    <h1 class="h-subtitle margin">其它优势</h1>
    <v-row>
      <v-col cols="12" md="6">
        <div class="box1">
          <img src="@/assets/images/Group 97839.png" alt="" />
          <div>稳定低延迟。自研 Nenly-RTC， 最高支持 4K 画质、60 帧率。</div>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div class="box1">
          <img src="@/assets/images/Group 97841.png" alt="" />
          <div>
            成本极致优化。云安卓容器动态部署， 高效资源调度，支持虚拟化 GPU。
          </div>
        </div>
      </v-col>
    </v-row>
    <img
      src="@/assets/images/Group 97872.png"
      class="d-none d-md-block"
      style="width: 100%; margin-top: 64px"
      alt=""
    />
    <img
      src="@/assets/images/Group 97987.png"
      class="d-md-none"
      style="width: 100%; margin-top: 32px"
      alt=""
    />
  </v-container>
</template>

<script>
export default {
  name: 'Advantages',
}
</script>

<style lang="scss" scoped>
// advantages
.advantages {
  padding: 0 18px !important;
  .img {
    height: 200px;
    max-width: 100%;
  }
  .margin {
    margin-top: 64px;
  }
  .h-subtitle {
    font-size: 42px;
    text-align: center;
    margin-bottom: 32px;
    text-shadow: 0 0 4px rgba(255, 255, 255, 0.4);
  }
  .cols {
    .box {
      padding: 16px 24px;
      img {
        height: 58px;
        margin: 0 24px 0 0;
      }
      h2 {
        font-size: 22px;
      }
      h3 {
        font-size: 14px;
        font-weight: normal;
        margin-top: 12px;
      }
    }
  }
  .box2 {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(209.62deg, #212121 13.78%, #2b2b2b 83.18%);
    opacity: 0.9;
    box-shadow: 0 7px 57px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 18px;
    border: 4px solid #262626;
    box-sizing: border-box;
    img {
      height: 30px;
    }
    .point {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background: #5fb6b9;
      filter: blur(1px);
      margin-right: 12px;
    }
    h2 {
      font-size: 18px;
      margin-bottom: 8px;
    }
    h3 {
      font-size: 14px;
      font-weight: normal;
    }
    .box2-title {
      display: flex;
      align-items: center;
    }
  }
  .box1 {
    background: linear-gradient(209.62deg, #212121 13.78%, #2b2b2b 83.18%);
    opacity: 0.9;
    box-shadow: 0px 7px 57px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 30px;
    font-size: 18px;
    border: 4px solid #262626;
    box-sizing: border-box;
    img {
      height: 62px;
      margin-right: 30px;
    }
  }
  .box {
    margin: 0 auto;
    .box-title {
      font-size: 36px;
      font-weight: bold;
    }
    .box-content {
      font-size: 18px;
      margin-top: 32px;
    }
    img {
      display: block;
      margin: 0 auto;
    }
    .left {
      padding-right: 25px;
    }
    .right {
      padding-left: 25px;
    }
  }
}
@media screen and (max-width: 600px) {
  .advantages {
    padding-left: 18px !important;
    padding-right: 18px !important;
    .margin {
      margin-top: 32px;
    }
    .img {
      width: 100%;
      height: auto !important;
      display: block;
    }
    .h-subtitle {
      font-size: 32px;
      margin-bottom: 12px;
    }
    .box1 {
      font-size: 14px;
      padding: 22px;
      img {
        width: 45px;
        height: auto;
        margin-right: 22px;
      }
    }
    .cols {
      padding: 8px 12px;
    }
    .box {
      .box-title {
        font-size: 28px;
      }
      .box-content {
        font-size: 16px;
      }
      .divider {
        width: 100%;
        border-top: 1px solid #3c3c3c;
        margin-bottom: 20px;
      }
      .left {
        padding-right: 12px;
      }
      .right {
        padding-left: 12px;
      }
    }
  }
}
</style>
