<template>
  <v-app v-scroll="onScroll">
    <HeaderBar v-if="$route.path !== '/privacy'" :idx.sync="idx" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import HeaderBar from '@/components/HeaderBar'
// import Home from '@/views/Home'
export default {
  name: 'App',

  components: {
    HeaderBar,
    // Home,
  },

  data: () => ({
    idx: 0,
  }),

  methods: {
    onScroll() {
      let top = document.body.scrollTop || document.documentElement.scrollTop
      // console.log(top);
      if (top > 0 && top <= 690) {
        this.idx = 0
      } else if (top > 690 && top <= 1348) {
        this.idx = 1
      } else if (top > 1348 && top <= 3535) {
        this.idx = 2
      } else if (top > 3535 && top <= 4237) {
        this.idx = 3
      } else if (top > 4237 && top <= 4966) {
        this.idx = 4
      } else if (top > 4966 && top <= 5690) {
        this.idx = 5
      } else if (top > 5690) {
        this.idx = 6
      }
    },
  },
}
</script>

<style lang="scss">
@font-face {
  font-family: 'PingFang SC';
  src: url('./assets/fonts/PingFangSCMedium.ttf');
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: 'PingFang SC';
  src: url('./assets/fonts/PingFang SC.ttf');
  font-weight: bold;
  font-display: swap;
}
@font-face {
  font-family: 'PingFang SC';
  src: url('./assets/fonts/PingFangSC-Thin.ttf');
  font-weight: 100;
  font-display: swap;
}
* {
  font-family: 'PingFang SC', Arial, Avenir, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
}
.theme--dark {
  background: #212121 !important;
}

.wrapper {
  padding: 24px 0 64px !important;
  overflow: visible;
  max-width: 1100px !important;
}
.box {
  position: relative;
  background: linear-gradient(209.62deg, #212121 13.78%, #2b2b2b 83.18%);
  opacity: 0.9;
  box-shadow: 0 7px 57px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  border: 4px solid #262626;
  padding: 36px;
  box-sizing: border-box;
  &::after {
    position: absolute;
    top: 16px;
    right: 16px;
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #5fb6b9;
    filter: blur(1px);
    // opacity: 0.3;
  }
}
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  .mask {
    background-color: rgba($color: #000000, $alpha: 0.7);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  .dialog-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 640px;
    box-sizing: border-box;
    padding: 42px;
    background: linear-gradient(209.62deg, #212121 13.78%, #2b2b2b 83.18%);
    box-shadow: 0px 7px 57px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    border: 4px solid #262626;
    z-index: 1001;
    .close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      transform: rotate(45deg);
      .line {
        border-radius: 2px;
        background-color: #fff;
      }
      .line-1 {
        width: 24px;
        height: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .line-2 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 2px;
        height: 24px;
      }
    }
    .box-title {
      display: flex;
      align-items: center;
      font-size: 28px;
      font-weight: bold;
      .badge {
        background-color: rgba($color: #fff, $alpha: 0.1);
        border-radius: 2px;
        padding: 2px 8px;
        font-size: 14px;
        color: #ccc;
        margin-left: 15px;
      }
    }
    .box-content {
      padding: 24px 0;
      font-size: 14px;
      .block {
        padding-bottom: 24px;
        p {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }
}
.h-title {
  font-size: 48px;
  text-align: center;
  text-shadow: 0 0 4px rgba(255, 255, 255, 0.4);
  font-weight: bold;
  &.margin {
    margin-bottom: 32px;
  }
}
@media screen and(max-width: 600px) {
  .h-title {
    font-size: 30px;
    &.margin {
      margin-bottom: 12px;
    }
  }
  .wrapper {
    padding-top: 24px !important;
    overflow: hidden;
  }
  .box {
    padding: 32px;
  }
}
</style>
